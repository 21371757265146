body.homepage {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.92);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.58;
  margin: auto;
  padding: 10px 20px 5px;
  //max-width: 800px;
  h1 {
    font-size: calc(6vw + 32px);
    line-height: .8;
    font-weight: 800;
    //font-family: 'Source Code Pro', Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
    //letter-spacing: -2px;
    margin-top: 0;
  }
}
.blog-entry {
  display: flex;
  justify-content: center;
  align-content: space-between;
  padding-top: 40px;
  margin-top: 60px;
  border-top: 10px dashed #4772d9;
  &:first-of-type {
    margin-top: 20px;
  }
  .blog-entry__title {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    margin-right: 40px;
    text-align: right;
    flex-basis: 34%;
    a {
      font-size: 2em;
      color: rgba(0, 0, 0, 0.92);
      font-weight: 800;
      line-height: 1;
      text-align: right;
      font-weight: 800;
      //font-family: 'Source Code Pro', Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
      //letter-spacing: -2px;
      p {
        margin: 5px auto;
      }
    }
  }
  .blog-entry__subtitle {
    font-size: 18px;
    line-height: 18px;
    font-weight: 400;
  }
  .blog-entry__date {
    margin: 5px 0;
    font-size: 75%;
    color: rgba(0, 0, 0, 0.92);
    line-height: 1;
    text-align: right;
  }
  .blog-entry__preview {
    display: flex;
    align-items: center;
    border-left: 5px solid #4772d9;
    flex-basis: 66%;
    padding-left: 20px;
    color: rgba(0, 0, 0, 0.6);
  }
  &.blog-entry--right {
    flex-direction: row-reverse;
    .blog-entry__title {
      margin-left: 40px;
      text-align: left;
    }
    .blog-entry__preview {
      padding-left: 0;
      padding-right: 20px;
      border-left: none;
      border-right: 5px solid #4772d9;
    }
  }
  .blog-entry__title a {
    background: none;
    transition: box-shadow 0.2s;
    -webkit-box-shadow: inset 0 -2px 0 #4772d9;
    box-shadow: inset 0 -2px 0 #4772d9;
    text-decoration: none;
  }
  .blog-entry__title a:hover {
    -webkit-box-shadow: inset 0 0 0 #4772d9, 0 3px 0 #4772d9;
    box-shadow: inset 0 0 0 #4772d9, 0 3px 0 #4772d9;
    background: none;
  }
  @media screen and (max-width: 768px) {
    justify-content: center;
    align-items: center;
    &.blog-entry--right {
      flex-direction: column;
    }
    &.blog-entry--right .blog-entry__title,
    .blog-entry__title {
      margin-right: 0;
      margin-left: 0;
    }
    flex-direction: column;
    .blog-entry__preview {
      justify-content: center;
      align-items: center;
      border-left: none;
    }
    &.blog-entry--right {
      .blog-entry__preview {
        border-right: none;
      }
    }
    .blog-entry__title {
      justify-content: center;
      align-items: center;
      a {
        text-align: center;
      }
    }
  }
}
.read-more-link {
  text-align: center;
  margin: 20px auto;
  a {
    background: none;
    border: 1px solid #4772D9;
    box-shadow: none;
    text-decoration: none;
    font-size: 24px;
    padding: 5px 20px;
    border-radius: 5px;
  }
  a:hover {
    cursor: pointer;
    background-color: #4772D9;
    color: white;
  }
}
