main.post {
  //padding-top: 200px;
}
section.post-title {
  margin-bottom: 30px;
  h1 {
    font-size: calc(5vw + 22px);
    margin: 10px 0 20px;
    line-height: .9;
  }
}
.post-title h1 span {
  display: block;
  font-size: 40%;
  font-weight: 200;
  margin-top: 20px;
  font-style: italic;
  @media screen and (max-width: 768px) {
    font-size: 18px;
  }
}
.post-intro {
  padding: 0 0 0 40px;
  border-left: 2px solid $blue;
  p {
    font-size: 16px;
    }
}
