@import 'base/reset';
@import 'base/colors';
@import 'base/defaults';
@import 'base/prism';
@import 'home';
@import 'post';

main {
  width: 900px;
  max-width: 90%;
  margin: auto;
}
