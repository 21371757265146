body {
  font-family: 'Libre Franklin', sans-serif;
}

h1,
h2,
h3,
h4,
h5 {
  margin-top: 60px;
  border-top: 2px dashed $blue;
  padding-top: 10px;
  font-weight: 800;
  //font-family: 'Source Code Pro', Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
  //letter-spacing: -2px;
}

h1 {
  font-size: calc(4vw + 16px);
  font-weight: 900;
  border-top: none;
  @media screen and (max-width: 768px) {
    font-size: calc(4.5vw + 16px);
  }
}

h2 {
  font-size: 4rem;
  font-size: calc(2.5vw + 16px);
  font-weight: 900;
  line-height: 0.8;
  span {
    font-size: calc(1.5vw + 16px);
  }
  @media screen and (max-width: 768px) {
    font-size: calc(3.5vw + 16px);
    span {
      font-size: calc(2.5vw + 16px);
    }
  }
}

h3 {
  font-size: 3rem;
  font-size: calc(1.5vw + 16px);
  font-weight: 900;
  span {
    font-size: calc(1.5vw + 16px);
  }
  @media screen and (max-width: 768px) {
    font-size: calc(2.5vw + 16px);
  }
}
hr {
  margin: 0;
}

li,
p {
  font-weight: 300;
  font-size: calc(0.5vw + 16px);
}
strong {
  font-weight: 800;
}
ul {
  margin-top: 0;
}

a.btn,
.btn {
  background-color: $blue;
  color: white;
  margin: 20px auto 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  font-size: 18px;
  border: 2px solid $blue;
  transition-duration: 0.3s;
  transition-property: color, background-color;
  height: 7vh;
  min-height: 45px;
  width: 200px;
  min-width: 20vw;
  font-size: calc(0.5vw + 16px);
  &:hover {
    animation: shadow-drop-2-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94)
      both;
    background-color: white;
    color: $blue;
    cursor: pointer;
  }
}

.btn--invert {
  background-color: white;
  color: $blue;
  &:hover {
    background-color: $blue;
    color: white;
  }
}

.clickable,
a:not(.btn) {
  background: linear-gradient(to bottom, $blue 0%, $blue 100%);
  background-position: 0 100%;
  background-repeat: repeat-x;
  background-size: 2px 2px;
  color: #000;
  text-decoration: none;
  transition: background-size 0.3s, background-color 0.3s, color 0.3s;
}

code {
  font-weight: 600;
  color: #dd4a68;
}
code.prism {
  font-weight: unset;
  color: unset;
}

.strikethrough {
  font-weight: 400;
  position: relative;
  &:before {
    content: '';
    width: 100%;
    position: absolute;
    top: calc(50% + 1.5px);
    border-top: 3px solid #000;
  }
}
blockquote {
  border-left: 2px solid $blue;
  margin-left: 0;
  padding-left: 40px;
  font-style: italic;
}

.clickable:hover,
a:hover {
  cursor: pointer;
  background-size: 4px 90%;
  color: white;
}

nav {
  height: 70px;
  padding: 10px;
  font-family: 'Source Code Pro', Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono',
    monospace;
  border-bottom: 2px dashed #333;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 768px) {
    justify-content: center;
  }

  a.nav-link {
    background: none;
    box-shadow: none;
    font-size: 18px;
    padding: 5px;
    border-radius: 2px;

    &:hover {
      background-color: $blue;
    }
  }
}
.image-container {
  width: 100%;
  text-align: center;
  padding: 5%;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
  img {
    max-width: 100%;
    &.ss {
      border: 1px dashed rgba(0, 0, 0, 0.5);
    }
  }
}

.scroll-up {
  position: fixed;
  border: none;
  bottom: 20px;
  right: 20px;
  background-color: $blue;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  color: white;
  justify-content: center;
  align-items: center;
  display: none;
  animation: slide-out-blurred-right 0.3s cubic-bezier(0.755, 0.05, 0.855, 0.06)
    both;
      cursor: pointer;

  &[aria-hidden='false'] {
    animation: slide-in-blurred-right 0.5s cubic-bezier(0.23, 1, 0.32, 1) both;
  }
  &[initial-state='false'] {
    display: flex;
  }
}

@keyframes shadow-drop-2-center {
  0% {
    transform: translateZ(0);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    transform: translateZ(50px);
    box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.25);
  }
}

@keyframes slide-in-blurred-right {
  0% {
    transform: translateX(200px) scaleX(2.5) scaleY(0.2);
    transform-origin: 0% 50%;
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    transform: translateX(0) scaleY(1) scaleX(1);
    transform-origin: 50% 50%;
    filter: blur(0);
    opacity: 1;
  }
}
@keyframes slide-out-blurred-right {
  0% {
    transform: translateX(0) scaleY(1) scaleX(1);
    transform-origin: 50% 50%;
    filter: blur(0);
    opacity: 1;
  }
  100% {
    transform: translateX(200px) scaleX(2) scaleY(0.2);
    transform-origin: 0% 50%;
    filter: blur(40px);
    opacity: 0;
  }
}
